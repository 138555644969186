import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button } from "../../components/Core";

import Img from "gatsby-image";

import { useStaticQuery, graphql } from "gatsby";

const CardImage = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 500px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

const SocialsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 130px;
  margin: 1rem auto 0;
`;

const BtnContainer = styled.div`
  margin: 3rem auto;
`;

export const TeamCard = ({ userImg, title, children, socials, ...rest }) => (
  <Box
    className="text-center"
    pt="15px"
    px="30px"
    borderRadius={10}
    mb={4}
    {...rest}
  >
    <CardImage>
      <Img fixed={userImg} alt={title} />
    </CardImage>
    <SocialsContainer>
      {socials?.facebook && (
        <a href={socials?.facebook} target="_blank" rel="noreferrer noopener">
          <i className="icon icon-logo-facebook" />
        </a>
      )}
      {socials?.twitter && (
        <a href={socials?.twitter} target="_blank" rel="noreferrer noopener">
          <i className="icon icon-logo-twitter" />
        </a>
      )}
      {socials?.instagram && (
        <a href={socials?.instagram} target="_blank" rel="noreferrer noopener">
          <i className="icon icon-logo-instagram" />
        </a>
      )}
      {socials?.linkedin && (
        <a href={socials?.linkedin} target="_blank" rel="noreferrer noopener">
          <i className="icon icon-logo-linkedin" />
        </a>
      )}
      {socials?.website && (
        <a href={socials?.website} target="_blank" rel="noreferrer noopener">
          <i className="icon icon-pc-monitor" />
        </a>
      )}
    </SocialsContainer>
    {!socials && <div style={{ height: "24px" }} />}
    <div className="text-center">
      <Title variant="card" fontSize="24px" letterSpacing={-0.75} my={1} mb={3}>
        {title}
      </Title>
      <Text fontSize={2} lineHeight={1.75}>
        {children}
      </Text>
    </div>
  </Box>
);

const Team = () => {
  const data = useStaticQuery(graphql`
    query {
      team: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/team//" } }
        sort: { fields: frontmatter___date, order: ASC }
      ) {
        nodes {
          id
          frontmatter {
            title
            description
            socials {
              facebook
              instagram
              linkedin
              twitter
              website
            }
            image {
              childImageSharp {
                fixed(width: 100, height: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      {/* <!-- Team section --> */}
      <Section className="position-relative pt-5 mt-4 pb-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" className="text-center py-3">
              <div className="">
                <Title color="secondary">Meet the EGOals team</Title>
              </div>
            </Col>
          </Row>
          <Row className="align-items-start justify-content-center">
            <Row className="align-items-start justify-content-center">
              {data.team.nodes.map((item) => {
                const { title, description, socials } = item.frontmatter;
                return (
                  <Col
                    sm="6"
                    md="5"
                    lg="4"
                    className="mt-3 mt-lg-4"
                    key={item.id}
                  >
                    <TeamCard
                      userImg={item.frontmatter.image.childImageSharp.fixed}
                      title={title}
                      socials={socials}
                    >
                      {description}
                    </TeamCard>
                  </Col>
                );
              })}
            </Row>
          </Row>
          <Row>
            <BtnContainer>
              <Button gatsbyLink to="/contributors">
                See All Our Contributors
              </Button>
            </BtnContainer>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Team;
